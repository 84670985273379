import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";

import Half8Solid from "../icons/half-8-solid.svg";
import Half2Solid from "../icons/half-2-solid.svg";

const Esigenza = () => {
  const { esigenza } = useStaticQuery(graphql`
    {
      esigenza: file(relativePath: { eq: "ogni-esigenza.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <Layout>
      <Seo
        title={`Starter o Enterprise: una Intranet per ogni esigenza`}
        description={`Pensata per rispondere ad ogni esigenza: dalla prima esperienza di Intranet all'evoluzione del tuo Digital Workplace avanzato. Scegli quello che fa per te!`}
      />

      <div className="page-detail">
        <div className="hero">
          <div className="container-lg position-relative">
            <Half2Solid className="ico-svg ico-half-2-solid ico-right" />
            <Half8Solid className="ico-svg ico-half-8-solid ico-left" />
            <div className="row">
              <div className="col-10 col-md-8">
                <h1 className="hero__title">
                  Un prodotto, due versioni:
                  <br />
                  <strong>Starter</strong> e <strong>Enterprise</strong>
                </h1>
              </div>
            </div>
          </div>
          <div className="hero__text-scroll hero__text-scroll--classic">
            <div className="text-scroll" aria-hidden="true">
              <div className="text-scroll__wrap">
                <span className="text-uppercase esigenza">Esigenza</span>
              </div>
              <div className="text-scroll__wrap text-scroll__wrap--repeat">
                <span className="text-uppercase esigenza">Esigenza</span>
              </div>
            </div>
          </div>
        </div>

        <div className="page-detail__intro">
          <div className="container-lg">
            <div className="row">
              <div className="col-12">
                <div className="d-flex flex-column flex-md-row align-items-end">
                  <div className="page-detail__intro-text">
                    <p>
                      Un prodotto, due versioni: per chi è alla{" "}
                      <strong>prima esperienza intranet</strong> e per chi ne possiede già una ma
                      vuole <strong>evolvere il proprio Digital Workplace</strong>.
                    </p>
                  </div>
                  <div className="page-detail__img-wrap">
                    <GatsbyImage
                      image={getImage(esigenza)}
                      alt="img Per ogni esigenza"
                      objectPosition="center center"
                      className="page-detail__img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-detail__text">
          <div className="container-lg pb-4">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1  col-lg-8 offset-lg-2">
                <h2 className="mt-0">Intranet 80.20 Piano Starter</h2>
                <p>
                  E’ la versione studiata per una <strong>prima esperienza intranet</strong>.
                </p>
                <p>Il Piano Starter comprende:</p>
                <ul>
                  <li>
                    le <strong>funzionalità essenziali</strong> per la comunicazione interna e per
                    il coinvolgimento degli utilizzatori ai fini di una immediata adozione;
                  </li>
                  <li>
                    un <strong>minimo impegno</strong> di manutenzione da parte di chi si deve
                    occupare del suo aggiornamento;
                  </li>
                  <li>
                    una <strong>spiccata modularità</strong>, grazie ad un completo catalogo di
                    componenti opzionali che permettono di evolvere nel tempo la propria Intranet,
                    nelle direzioni ritenute più opportune.
                  </li>
                </ul>
                <p>
                  Comunicazione, ricerca documentale, my page, social, profilazione, tutto{" "}
                  <strong>“ready to use”</strong> e <strong>“mobile responsive”</strong> per un
                  progetto di Intranet moderno e funzionale.
                </p>
                <h2>Intranet 80.20 Piano Enterprise</h2>
                <p>
                  E’ la versione studiata per le aziende che intendano{" "}
                  <strong>evolvere l’attuale esperienza Intranet</strong>.
                </p>
                <p>Il Piano Enterprise comprende:</p>
                <ul>
                  <li>
                    una ricchezza funzionale in grado di supportare le{" "}
                    <strong>esigenze più evolute</strong>, anche nell’ottica di digital workplace;
                  </li>
                  <li>
                    un processo di implementazione e <strong>personalizzazione</strong> del prodotto
                    al contesto aziendale basato su:
                    <ul>
                      <li>
                        l’esperta consulenza di Giacomo Mason per le fasi di analisi dell’ambiente
                        ai fini di indirizzare le <strong>scelte strategiche</strong> in accordo con
                        gli stakeholder;
                      </li>
                      <li>
                        l’opera di un team di design che cura gli{" "}
                        <strong>aspetti di usabilità</strong> (UX) e personalizzazione interfaccia
                        (UI);
                      </li>
                      <li>
                        il coordinamento di un team tecnico che conduce le analisi sugli aspetti di{" "}
                        <strong>integrazione e di project management</strong> ed in collaborazione
                        con l’IT aziendale definisce le modalità e il piano di implementazione.
                      </li>
                    </ul>
                  </li>
                  <li>
                    un approccio analogo ad una realizzazione <strong>“su misura”</strong> con il
                    vantaggio di disporre di strumenti e componenti software pronti e{" "}
                    <strong>ingegnerizzati</strong>, professionalità dotate di grande esperienza con
                    il supporto di un <strong>processo consolidato</strong>, più volte sperimentato
                    nei confronti di <strong>grandi organizzazioni</strong>, anche internazionali.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Esigenza;
